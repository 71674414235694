import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import React, { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import Select from "react-select";
import { states } from "../../../shared/constants/selectOptions";
import { formatDateAndTime } from "../../../shared/utils/formatDate";
import { initBookAppointment } from "../../constants/initialForms";
import { validateSelections } from "../../services/medEvals.service";
import Calendar from "./Calendar";
import { useNavigate } from "react-router-dom";
import { medEvalRoutes } from "../../routes/routes";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import BeforeLoginModal from "../../../proffer_module/components/Modals/BeforeLogin";
import { toast } from "react-toastify";
import { showErrorToast } from "../../../shared/utils/toast";

const BookSelectors: React.FC = () => {
  const [bookAppointmentForm, setBookAppointmentForm] =
    useState(initBookAppointment);
  const [stateOptions, setStateOptions] = useState<
    { value: string; label: string }[]
  >([{ value: "Maryland", label: "Maryland" }]);
  const [selectedDateTime, setSelectedDateTime] = useState<string | null>(null);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showBeforeLoginModal, setShowBeforeLoginModal] = useState(false);

  const { User } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const serviceOptions = [
    { value: "medical evaluation", label: "Medical Evaluation" },
  ];

  //Currently only Maryland will be there. Uncomment to get all the states
  // const handleSetStates = () => {
  //   const temp = Object.values(states);
  //   const stateOptions = temp.map((item) => ({ value: item, label: item }));
  //   setStateOptions(stateOptions);
  // };
  // useEffect(() => {
  //   handleSetStates();
  // }, []);

  const handleSelect = (value: string, name: string) => {
    setBookAppointmentForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateTimeSelection = (date: Date, time?: string) => {
    const dateTimeString = `${date.toDateString()}/${time || ""}`;
    setSelectedDateTime(dateTimeString);
    setBookAppointmentForm((prev) => ({ ...prev, dateTime: dateTimeString }));
  };

  const handleOpenBeforeLoginModal = () => {
    setShowBeforeLoginModal(true);
  };

  const handleCloseBeforeLoginModal = () => {
    setShowBeforeLoginModal(false);
  };

  const handleBookAppointment = () => {
    if (!User?._id) {
      handleOpenBeforeLoginModal();
    } else if (!User?.name) {
      showErrorToast(
        "Please complete your profile information in edit profile to Book your appointment"
      );
    } else {
      handleBooking();
    }
  };

  const handleBooking = async () => {
    const appointment = formatDateAndTime(bookAppointmentForm?.dateTime);

    const resp = await validateSelections(
      bookAppointmentForm?.state,
      bookAppointmentForm?.service,
      appointment
    );
    if (resp?.validate && resp?.validate === true) {
      console.log("valid");
      navigate(medEvalRoutes?.confirm_booking, {
        state: {
          state: bookAppointmentForm?.state,
          service: bookAppointmentForm?.service,
          appointment: appointment,
        },
      });
    }
    console.log(resp);
  };

  useEffect(() => {
    const checkFormCompletion = () => {
      const { state, service, dateTime } = bookAppointmentForm;
      if (state && service && dateTime) {
        return true;
      } else {
        return false;
      }
    };

    setIsFormComplete(checkFormCompletion());
  }, [bookAppointmentForm]);

  return (
    <>
      <div className="flex items-center md:flex-row md:justify-center flex-col md:items-start gap-4 xl:gap-9 w-full sm:max-w-[1085px] pt-6 md:pt-12 xl:pt-20 pb-6 md:pb-12 xl:pb-24 mx-auto">
        <div className="grid grid-cols-12 gap-4 w-full">
          <div className="col-span-12 sm:col-span-6 xl:col-span-4 w-full">
            <Select
              className="text-start w-full xl:max-w-[230px]"
              placeholder="Select a State"
              isClearable
              options={stateOptions}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "grey" : "#CED2DA",
                  padding: "8px 0 8px 8px",
                  borderRadius: "8px",
                }),
              }}
              value={stateOptions.find(
                (opt) => opt.value === bookAppointmentForm.state
              )}
              onChange={(e) => {
                handleSelect(e?.value as string, "state");
              }}
            />
          </div>
          <div className="col-span-12 sm:col-span-6 xl:col-span-4 w-full">
            <Select
              className="text-start w-full xl:max-w-[230px]"
              placeholder="Select a Service"
              isClearable
              options={serviceOptions}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "grey" : "#CED2DA",
                  padding: "8px 0 8px 8px",
                  borderRadius: "8px",
                }),
              }}
              value={serviceOptions.find(
                (opt) => opt.value === bookAppointmentForm.service
              )}
              onChange={(e) => {
                handleSelect(e?.value as string, "service");
              }}
            />
          </div>
          <div className="col-span-12 sm:col-span-6 xl:col-span-4 w-full">
            <DropdownMenu.Root>
              <DropdownMenu.Trigger
                className={`relative border pe-2 ps-4 py-3 min-h-[54px] w-full h-full font-inter border-[#CED2DA] text-left ${
                  selectedDateTime ? "text-secondary" : "text-[#808080]"
                } rounded-lg flex items-center justify-between`}
              >
                {selectedDateTime || "Select Date/Time"}{" "}
                <HiChevronDown size={24} />
              </DropdownMenu.Trigger>
              <DropdownMenu.Content
                className="absolute top-2 left-0 right-0 w-[280px] sm:w-[408px]  shadow-md  p-4 rounded-lg border border-secondaryVariant2/50 px-4 pb-6 bg-white z-20"
                side="left"
                align="end"
              >
                <Calendar onDateTimeSelect={handleDateTimeSelection} />
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </div>
        </div>
        <button
          onClick={handleBookAppointment}
          className={`btnPrimary max-w-[233px] ${
            !isFormComplete ? "opacity-50" : ""
          }`}
          disabled={!isFormComplete}
        >
          Book your appointment
        </button>
        {showBeforeLoginModal && (
          <BeforeLoginModal
            handleBeforeLoginModal={handleOpenBeforeLoginModal}
            onClose={handleCloseBeforeLoginModal}
          />
        )}
      </div>
    </>
  );
};

export default BookSelectors;
