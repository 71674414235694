import React, { useRef } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { GoArrowRight } from "react-icons/go";
import { STORAGE_KEYS } from "../../../shared/enums/sharedEnums";
import {
  clientID,
  identityServerURI,
  redirectURI,
} from "../../../shared/constants/environment.constants";
import { useLocation } from "react-router-dom";
import useOutsideClick from "../../../shared/hooks/useOutsideClick";

interface ModalProps {
  handleBeforeLoginModal: () => void;
  onClose: () => void;
}

const BeforeLoginModal: React.FC<ModalProps> = ({ onClose }) => {
  const beforeLoginRef = useRef<HTMLDivElement>(null);
  const checkAuthorization = localStorage.getItem(STORAGE_KEYS?.ACC_TOKEN);
  const pathName = useLocation().pathname;
  const handleSignInClick = () => {
    // const checkAuthorization = localStorage.getItem(storageKeys?.accToken);
    if (!checkAuthorization) {
      localStorage.setItem(STORAGE_KEYS.PATH_NAME, window.location.pathname);
      window.location.replace(
        `${identityServerURI}${"?client_id=" + clientID}${
          "&redirect_URI=" + redirectURI
        }`
      );
    } else {
      onClose();
    }
  };
  useOutsideClick(beforeLoginRef, () => onClose());

  return (
    <>
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
        <div
          className="bg-white p-5 md:p-9 rounded-2xl shadow-lg max-w-[505px] w-full"
          ref={beforeLoginRef}
        >
          <div className="flex items-start justify-between mb-4 md:mb-9">
            <img src="../images/web-logo.png" alt="web logo" />
            <button onClick={onClose}>
              <IoCloseOutline className="w-6 h-6 text-secondaryVariant" />
            </button>
          </div>
          <h2 className="text-xl lg:text-2xl text-secondary font-semibold mb-5">
            {"Please Login/Register to Continue "}
            {pathName.includes("md") ? "Booking" : "PrOffer."}
          </h2>
          <p className="text-base text-secondaryVariant mb-4 md:mb-9">
            {/* Access personalized content, special benefits, and additional
            resources by logging in now. */}
            You will then be able to access personalized content, special
            benefits, and additional resources
          </p>
          <button
            className="btnPrimary max-w-[195px] flex justify-center items-center gap-2"
            onClick={handleSignInClick}
          >
            Login / Register <GoArrowRight className="w-5 h-5" />
          </button>
        </div>
      </div>{" "}
    </>
  );
};

export default BeforeLoginModal;
