
export const regexPatterns = {
    name: /^.{1,50}$/, // Max 50 characters, non-empty
    centerName: /^.{1,100}$/, // Max 50 characters, non-empty
    email: /^(?!.*\s)[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, // Email pattern
    password:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{12,})[^'"\s]{12,}$/, // Password pattern
    phoneNumber: /^.{0,15}$/,
    postalCode: /^\d{5}(?:[-\s]\d{4})?$/
  };
  