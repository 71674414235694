export const medEvalRoutes = {
    eval_center_home: "/md/home",
    eval_teacher_home: "/md/evaluation-teacher",
    date: "/md/date",
    book_appointment: "/md/book-appointment",
    dashboard: "/md/dashboard",
    confirm_booking: "/md/confirm-booking",
    eval_successful: "/md/evaluation-successful",
    // center_dashboard: "/md/center-dashboard",
    // center_history: "/md/center-history",
    // eval_dashboard: "/md/dashboard",
}