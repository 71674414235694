export const sharedRoutes = {
  about_us: "/about-us",
  contact_us: "/contact-us",
  auth_verify: "/auth/verify",
  privacy_policy: "/privacy-statement",
  terms_and_conditions: "/terms-conditions",
  vacancies_dashboard: "/vacancies-dashboard",
  dashboard_payment_method: "/dashboard-payment-method",
  blogs_screen: "/blogs-screen",
  blogs_details: "/blogs-details",

  coming_soon: "/coming-soon",
};
