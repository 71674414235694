import React, { useEffect, useState } from "react";
import { FiThumbsUp } from "react-icons/fi";
import { TbMessageCircle } from "react-icons/tb";
import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";
import MainWrapper from "../../components/layout/MainWrapper";
import BlogCards from "../../components/blogCards/BlogCards";
import { GoShareAndroid } from "react-icons/go";
import CommentCard from "../../components/commentCard/CommentCard";
import { useLocation } from "react-router-dom";
import { formatDateFull } from "../../utils/formatDate";
import DOMPurify from "dompurify";

const BlogDetails = () => {
  const [scrollUp, setScrollUp] = useState(false);
  const blog = useLocation()?.state?.blog;
  const tooltipText: { [key: string]: string } = {
    "0": "Hit like",
    "1": "Add comment",
    "2": "Share",
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [scrollUp]);

  console.log("check ", scrollUp);
  const [id, setId] = useState("");
  if (!blog) {
    return <div>No Content to show</div>;
  }
  // const sanitizer = DOMPurify.sanitize;

    return (
    <>
      <MainWrapper>
        <div className="container">
          <div className="flex justify-center">
            <div className="py-8 lg:py-16 xl:py-24 max-w-[680px] space-y-5">
              <h2 className="text-2xl md:text-xll xl:text-4xl text-secondary font-semibold">
                {blog?.title}
              </h2>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-6">
                  <img
                    className="w-12 h-12 border-[#8F8F8F] border-[0.5px] rounded-full"
                    src="../images/blogsAdminAvatar.png"
                    alt="Avatar"
                  />
                  <h3 className="text-lg text-secondary font-semibold flex flex-col">
                    By {blog?.creator}
                    <span className="text-base text-secondaryVariant">
                      Published On: {formatDateFull(blog?.date)}
                    </span>
                  </h3>
                </div>
                <div className="flex items-center gap-4">
                  <button
                    data-tooltip-id="2"
                    onMouseOver={() => setId("2")}
                    onMouseLeave={() => setId("")}
                    className="flex me-4 items-center gap-2 text-sm text-secondaryVariant2 hover:text-statusColor hover:scale-110 transform transition-transform duration-200"
                  >
                    <GoShareAndroid className="w-6 h-6" />
                  </button>
                  <button
                    data-tooltip-id="0"
                    onMouseOver={() => setId("0")}
                    onMouseLeave={() => setId("")}
                    className="flex items-center gap-2 text-sm text-secondaryVariant2 hover:text-statusColor hover:scale-110 transform transition-transform duration-200"
                  >
                    <FiThumbsUp className="w-6 h-6" />
                    45
                  </button>
                  <button
                    data-tooltip-id="1"
                    onMouseOver={() => setId("1")}
                    onMouseLeave={() => setId("")}
                    className="flex items-center gap-2 text-sm text-secondaryVariant2 hover:text-statusColor hover:scale-110 transform transition-transform duration-200"
                  >
                    <TbMessageCircle className="w-6 h-6" />
                    23
                  </button>
                </div>
              </div>
              <img
                className=" rounded-xl w-full object-cover"
                src={blog?.imageUrl}
                alt="trending blog"
              />
              {/* <p className="text-base text-secondaryVariant text-justify">
                {blog?.content}
              </p> */}
              <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog?.content) }}
              />
            </div>
          </div>
        </div>
        <div>
          <BlogCards scrollUp={scrollUp} setScrollUp={setScrollUp} />
        </div>
        <TooltipComponent id={id} content={tooltipText[id]} />
      </MainWrapper>
    </>
  );
};

export default BlogDetails;
