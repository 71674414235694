import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { STORAGE_KEYS, USER_ROLES } from "../enums/sharedEnums";
import { profferRoutes } from "../../proffer_module/routes/routes";
import { ccfRoutes } from "../../ccfWebsite_module/routes/routes";

interface Props {
    role: string;
}
const ProtectedRoute: React.FC<Props> = ({ role }) => {
    const isAuthenticated = !!localStorage.getItem(STORAGE_KEYS.USER);
    const storedRole = localStorage.getItem(STORAGE_KEYS.ROLE);

    console.log("check is authenticated: ", isAuthenticated)
    if (!isAuthenticated) {
        return <Navigate to={ccfRoutes.home} />;
    }

    if (!storedRole || storedRole !== role) {
        switch (storedRole) {
            case USER_ROLES.CENTER:
                return <Navigate to={profferRoutes.dashboard} />;
            case USER_ROLES.PARENT:
                return <Navigate to={profferRoutes.proffer_list} />;
            default:
                // return <Navigate to={profferRoutes.home} />;
                break;
        }
    }

    return <Outlet />;
};

export default ProtectedRoute;
