import React, { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import LoaderSpinner from "../shared/components/Loader/Loader";
import { ccfRoutes } from "./routes/routes";

const CcfHomePage = lazy(() => import("./pages/ccf_HomePage/CcfHomePage"));
const CcfCenter = lazy(() => import("./pages/ccf_Center/CcfCenter"));
const CcfParent = lazy(() => import("./pages/ccf_Parent/CcfParent"));
const CcfTeacher = lazy(() => import("./pages/ccf_Teacher/CcfTeacher"));

const CCFModuleRoutes: RouteObject[] = [
  {
    path: ccfRoutes.home,
    element: (
      <Suspense fallback={< LoaderSpinner />} >
        <CcfHomePage />
      </Suspense>
    )
  },
  {
    path: ccfRoutes.center,
    element: (
      <Suspense fallback={< LoaderSpinner />}>
        <CcfCenter />
      </Suspense>
    )
  },
  {
    path: ccfRoutes.parent,
    element: (
      <Suspense fallback={< LoaderSpinner />}>
        <CcfParent />
      </Suspense>
    )
  },
  {
    path: ccfRoutes.teacher,
    element: (
      <Suspense fallback={< LoaderSpinner />}>
        <CcfTeacher />
      </Suspense>
    )
  },
];

export default CCFModuleRoutes;
