export enum USER_ROLES {
    PARENT = 'parent',
    CENTER = 'center super admin',
    TEACHER = 'teacher'
}

export enum STORAGE_KEYS {
    ACC_TOKEN = "accToken",
    RF_TOKEN = "rfToken",
    USER = "user",
    REMEMBER_ME = "rememberMe",
    PATH_NAME = "pathName",
    ROLE = "role",
    NAVIGATED = "navigated"
};
