import {
    GoogleMap,
    Marker,
    useJsApiLoader
} from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { ICoordinates } from "../../../proffer_module/types/IFindCenterParams.interface";
import { googleAPIKey } from "../../constants/environment.constants";

const containerStyle = {
    width: "100%",
    height: "100%",
};
const InitDefaultProps = {
 
    center: { lat: 39.1219948, lng: -76.6451697 },
    zoom: 50,
};
interface Props {
    ccfLocation: ICoordinates;
}
const ContactUsMaps: React.FC<Props> = ({ ccfLocation }) => {
    const [markLocation, setMarkLocation] = useState(InitDefaultProps?.center);
    const [map, setMap] = useState<google.maps.Map | null>(null);

    useEffect(() => {
        if (!ccfLocation?.lat || !ccfLocation?.lng) return;
        setMarkLocation((prev) => ({
            ...prev,
            lat: ccfLocation?.lat as number,
            lng: ccfLocation?.lng as number,
        }));
    }, [ccfLocation]);

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: googleAPIKey,
        libraries: ["places"],
    });

    const onLoad = useCallback(function callback(map: google.maps.Map) {
        const bounds = new window.google.maps.LatLngBounds(
            InitDefaultProps?.center
        );
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map: google.maps.Map) {
        setMap(null);
    }, []);


    const handleMapClick = (e: google.maps.MapMouseEvent) => {
        if (e?.latLng) {
            const newLocation = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
            };
            setMarkLocation(newLocation);
        }
    };


    if (!isLoaded) {
        return <div>Loading maps</div>;
    }
    return (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={markLocation}
                zoom={InitDefaultProps?.zoom}
                onClick={handleMapClick}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                <Marker position={markLocation} />
            </GoogleMap>
        </>
    );
};

export default ContactUsMaps;
