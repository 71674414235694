import React, { useEffect, useState } from "react";
import MainWrapper from "../../components/layout/MainWrapper";
import CenterViewBanner from "../../components/centerViewBanner/CenterViewBanner";
import Trending from "../../components/trending/Trending";
import BlogCards from "../../components/blogCards/BlogCards";
import { IBlog } from "../../../medEvaluation_module/types/blog.interface";

const BlogsScreen = () => {
  const [trendingBlog, setTrendingBlog] = useState<IBlog>();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <MainWrapper>
        <div className="mb-4 md:mb-10 xl:mb-16">
          <CenterViewBanner title={"Blogs"} imageClass={"blogsbanner"} />
        </div>
        <div className="container">
          <div className="mb-4 sm:mb-8 md:mb-16 xl:mb-24">
            <Trending trendingBlog={trendingBlog as IBlog} />
          </div>
        </div>
        <div>
          <BlogCards setTrendingBlog={setTrendingBlog} />
        </div>
      </MainWrapper>
    </>
  );
};

export default BlogsScreen;
