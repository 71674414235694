import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import LoaderSpinner from "../shared/components/Loader/Loader";
import { medEvalRoutes } from "./routes/routes";

const MedEvaluationTeacher = lazy(() => import("./pages/medEvaluationTeacher/MedEvaluationTeacher"));
const MedEvaluationDate = lazy(() => import("./pages/medEvaluationDate/MedEvaluationDate"));
const MedEvlConfirmBooking = lazy(() => import("./pages/medEvlConfirmBooking/MedEvlConfirmBooking"));
const BookAppointment = lazy(() => import("./pages/medEvaluationDashboard/BookAppointment"));
const MedEvaluationSuccessful = lazy(() => import("./pages/medEvaluationSuccessful/MedEvaluationSuccessful"));
const MedEvaluationCenter = lazy(() => import("./pages/medEvaluationCenter/MedEvaluationCenter"));
const MedEvaluationDashboard = lazy(() => import("./pages/medEvaluationDashboard/MedEvaluationDashboard"));
const MedEvlCenterDashboard = lazy(() => import("./pages/medEvaluationDashboard/MedEvlCenterDashboard"));
const MedEvlCenterHistory = lazy(() => import("./pages/medEvaluationDashboard/MedEvlCenterHistory"));

const MedEvalModuleRoutes: RouteObject[] = [


  {
    path: medEvalRoutes.eval_center_home,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <MedEvaluationCenter />
      </Suspense>
    )
  },
  {
    path: medEvalRoutes.eval_teacher_home,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <MedEvaluationTeacher />
      </Suspense>
    )
  },
  {
    path: medEvalRoutes.date,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <MedEvaluationDate />
      </Suspense>
    )
  },
  {
    path: medEvalRoutes.book_appointment,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <BookAppointment />
      </Suspense>
    )
  },
  {
    path: medEvalRoutes.dashboard,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <MedEvaluationDashboard />
      </Suspense>
    )
  },
  {
    path: medEvalRoutes.eval_successful,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <MedEvaluationSuccessful />
      </Suspense>
    )
  },
  {
    path: medEvalRoutes.confirm_booking,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <MedEvlConfirmBooking />
      </Suspense>
    )
  },
  // {
  //   path: medEvalRoutes.dashboard,
  //   element: (
  //     <Suspense fallback={<LoaderSpinner />}>
  //       <BookAppointment />
  //     </Suspense>
  //   )
  // },

  // {
  //   path: medEvalRoutes.center_dashboard,
  //   element: (
  //     <Suspense fallback={<LoaderSpinner />}>
  //       <MedEvlCenterDashboard />
  //     </Suspense>
  //   )
  // },
  // {
  //   path: medEvalRoutes.center_history,
  //   element: (
  //     <Suspense fallback={<LoaderSpinner />}>
  //       <MedEvlCenterHistory />
  //     </Suspense>
  //   )
  // },




];

export default MedEvalModuleRoutes;
