import { AiFillYoutube } from "react-icons/ai";
import { GrPhone } from "react-icons/gr";
import { HiOutlineLocationMarker, HiOutlineMail } from "react-icons/hi";
import { IoLogoInstagram } from "react-icons/io";
import { IoLogoTwitter } from "react-icons/io5";
import { TiSocialFacebook } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import { sharedRoutes } from "../../routes/routes";
import { adventureRoutes } from "../../../adventures_module/routes/routes";
import { profferRoutes } from "../../../proffer_module/routes/routes";
import { medEvalRoutes } from "../../../medEvaluation_module/routes/routes";
import { ccfRoutes } from "../../../ccfWebsite_module/routes/routes";
import { TooltipComponent } from "../tooltip/Tooltip";
import { useState } from "react";
const Footer = () => {
  const navigate = useNavigate();
  const handleNavToInBuild = (title: string) => {
    navigate(sharedRoutes.coming_soon, { state: { title } });
  };

  const handleEmailClick = () => {
    const mailtoLink = `mailto:childcare.support@gmail.com`;
    window.location.href = mailtoLink;
  };
  const handleLocationClick = () => {
    const address = "8064 New Cut Road, Odenton, MD 21144";
    const encodedAddress = encodeURIComponent(address);
    const locationLink = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(locationLink, "_blank");
  };

  const handlePhoneClick = () => {
    const phoneLink = `tel:0016676779200`;
    window.location.href = phoneLink;
  };

  const tooltipText: { [key: string]: string } = {
    "0": "Facebook",
    "1": "Twitter",
    "2": "Instagram",
    "3": "Youtube",
  };

  const [id, setId] = useState("");
  return (
    <>
      <div className="bg-natural -z-10">
        <div className="container">
          <div className="grid grid-cols-12 gap-5 lg-gap-3 py-6 md:py-10 xl:py-14">
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <img
                className="mb-4"
                src="../images/web-logo.png"
                alt="web logo"
              />
              <p className="text-base text-secondaryLight mb-5 md:mb-8 xl:mb-12 max-w-[303px]">
                Providing innovations to increase operational efficiency for
                Child Care Centers, decrease cost of Child Care for parents, and
                eliminate unnecessary hurdles for teachers.
              </p>
              <div className="flex items-center gap-5">
                <Link
                  data-tooltip-id="0"
                  onMouseOver={() => setId("0")}
                  onMouseLeave={() => setId("")}
                  to="https://www.facebook.com/profile.php?id=61553050964642"
                  target="_blank"
                  className="rounded-full bg-white p-2 hover:scale-125 transform transition-transform duration-200"
                >
                  <TiSocialFacebook className="w-5 h-5 text-primary" />
                </Link>
                <Link
                  data-tooltip-id="1"
                  onMouseOver={() => setId("1")}
                  onMouseLeave={() => setId("")}
                  to="#"
                  className="rounded-full bg-white p-2 hover:scale-125 transform transition-transform duration-200"
                >
                  <IoLogoTwitter className="w-5 h-5 text-primary" />
                </Link>
                <Link
                  data-tooltip-id="2"
                  onMouseOver={() => setId("2")}
                  onMouseLeave={() => setId("")}
                  to="#"
                  className="rounded-full bg-white p-2 hover:scale-125 transform transition-transform duration-200"
                >
                  <IoLogoInstagram className="w-5 h-5 text-primary" />
                </Link>
                <Link
                  data-tooltip-id="3"
                  onMouseOver={() => setId("3")}
                  onMouseLeave={() => setId("")}
                  to="#"
                  className="rounded-full bg-white p-2 hover:scale-125 transform transition-transform duration-200"
                >
                  <AiFillYoutube className="w-5 h-5 text-primary" />
                </Link>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4 grid grid-cols-12 gap-6">
              <div className="col-span-12 sm:col-span-6">
                <h3 className="text-base font-semibold text-secondary mb-4">
                  Services
                </h3>
                <div className="flex flex-col items-start gap-4">
                  <Link
                    to={profferRoutes.home}
                    className="text-base text-secondaryVariant hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    PrOffer
                  </Link>
                  <Link
                    to={medEvalRoutes.eval_center_home}
                    className="text-base text-secondaryVariant hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Medical Evaluation
                  </Link>
                  <button
                    onClick={() => {
                      handleNavToInBuild("Foray");
                    }}
                    className="text-base text-secondaryVariant hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Foray
                  </button>
                  <button
                    onClick={() => {
                      handleNavToInBuild("LMS");
                    }}
                    className="text-base text-secondaryVariant hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    LMS
                  </button>
                  <button
                    onClick={() => {
                      handleNavToInBuild("JCC Operations");
                    }}
                    className="text-base text-secondaryVariant hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    JCC Operations
                  </button>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6">
                <h3 className="text-base font-semibold text-secondary mb-4">
                  Quick link
                </h3>
                <div className="flex items-start flex-col gap-4">
                  <Link
                    to={ccfRoutes.home}
                    className="text-base text-secondaryVariant hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Home
                  </Link>
                  <Link
                    to={sharedRoutes.about_us}
                    className="text-base text-secondaryVariant hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    About Us
                  </Link>
                  <button
                    onClick={() => {
                      handleNavToInBuild("Consultation");
                    }}
                    className="text-base text-secondaryVariant hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Consultation
                  </button>
                  <Link
                    to={adventureRoutes.home}
                    className="text-base text-secondaryVariant hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Adventures
                  </Link>
                  <Link
                    to="#"
                    className="text-base text-secondaryVariant hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Blog
                  </Link>
                  <Link
                    to={sharedRoutes.contact_us}
                    className="text-base text-secondaryVariant hover:text-primary hover:scale-110 transform transition-transform duration-200"
                  >
                    Contact us{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-4">
              <h3 className="text-base font-semibold text-secondary mb-4">
                Contact Us
              </h3>
              <button
                onClick={handleLocationClick}
                className="text-base text-secondaryVariant mb-5 flex items-start gap-3 text-start hover:text-primary hover:scale-110 transform transition-transform duration-200"
              >
                <HiOutlineLocationMarker className="w-6 h-6 text-primary" />
                8064 New Cut Road, Odenton, MD 21144
              </button>
              <button
                onClick={handleEmailClick}
                className="text-base text-secondaryVariant mb-5 flex items-start gap-3 text-start hover:text-primary hover:scale-110 transform transition-transform duration-200"
              >
                <HiOutlineMail className="w-6 h-6 text-primary" />
                childcare.support@gmail.com
              </button>
              <button
                onClick={handlePhoneClick}
                className="text-base text-secondaryVariant mb-5 flex items-start gap-3 text-start hover:text-primary hover:scale-110 transform transition-transform duration-200"
              >
                <GrPhone className="w-6 h-6 text-primary" />
                667-677-9200
              </button>
            </div>
          </div>
          <hr className="text-secondaryLight" />
          <div className="flex flex-col items-center justify-center lg:flex-row text-center gap-2 py-6">
            <div className="flex flex-col items-center gap-2 md:flex-row">
              <Link
                to="#"
                className="text-base text-secondaryVariant md:border-r pe-2 hover:text-primary border-secondaryVariant"
              >
                Copyright © 2025 BRIX Agency
              </Link>
              <Link
                to="#"
                className="text-base text-secondaryVariant lg:border-r pe-2 hover:text-primary border-secondaryVariant"
              >
                All Rights Reserved
              </Link>
            </div>
            <div className="flex flex-col items-center gap-2 md:flex-row">
              <Link
                to={sharedRoutes.terms_and_conditions}
                className="text-base text-statusColor underline md:border-r pe-2 hover:text-primary border-secondaryVariant"
              >
                Terms and Conditions
              </Link>
              <Link
                to={sharedRoutes.privacy_policy}
                className="text-base text-statusColor underline pe-2 hover:text-primary"
              >
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        <TooltipComponent id={id} content={tooltipText[id]} />
      </div>
    </>
  );
};

export default Footer;
