import React, { useEffect, useState } from "react";
import { FiThumbsUp } from "react-icons/fi";
import { TbMessageCircle } from "react-icons/tb";
import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";
import { IBlog } from "../../../medEvaluation_module/types/blog.interface";
import { useNavigate } from "react-router-dom";
import { sharedRoutes } from "../../routes/routes";
import { formatDateBlog } from "../../utils/formatDate";
import moment from "moment";
import DOMPurify from "dompurify";

interface Props {
  trendingBlog: IBlog;
}
const Trending: React.FC<Props> = ({ trendingBlog }) => {
  const navigate = useNavigate();
  const tooltipText: { [key: string]: string } = {
    "0": "Hit like",
    "1": "Add comment",
  };
  const handleReadMore = (blog: IBlog) => {
    navigate(sharedRoutes.blogs_details, { state: { blog } });
  };
  const [content, setContent] = useState("");
  const sanitizer = DOMPurify.sanitize;
  const sanitizedContent = sanitizer(trendingBlog?.content);
  function getFirstNWords(content: string, wordCount: number) {
    const sanitizedContent = DOMPurify.sanitize(content);
    const wordsArray = sanitizedContent?.split(" ");

    const firstNWordsArray = wordsArray?.slice(0, wordCount);

    const firstNWords = firstNWordsArray?.join(" ");

    return firstNWords;
  }

  useEffect(() => {
    const cleanedContent = sanitizedContent.replace(/\<img[^>]*\>/g, "");
    setContent(cleanedContent);
  }, [sanitizedContent]);

  const [id, setId] = useState("");
  return (
    <>
      <div>
        <h2 className="text-2xl md:text-xll xl:text-4xl text-secondary font-bold mb-4 md:mb-8 xl:mb-12">
          Trending
        </h2>
        <div className="grid grid-cols-12 border-[0.5px] border-secondaryVariant2 rounded-xl">
          <div className="col-span-12 md:col-span-6 lg:py-6 p-4 lg:px-9 relative">
            <div className="flex items-center gap-3 mb-4 lg:mb-6">
              <span className="py-1 px-2.5 rounded-3xl bg-tertiaryVariant2 text-sm text-primary">
                Latest
              </span>
              <span className="text-sm text-secondaryVariant2">
                {formatDateBlog(trendingBlog?.pubDate)}
              </span>
            </div>
            <h2 className="text-2xl md:text-xll xl:text-4xl text-secondary font-bold mb-6 line-clamp-3">
              {trendingBlog?.title}
            </h2>
            <div className="flex flex-col justify-between gap-6">
              {/* <p className="text-secondaryVariant text-base line-clamp-6 mb-8">
                {getFirstNWords(trendingBlog?.content, 35)}...

              </p> */}
              <div
                className="line-clamp-6 max-w-[586px]  text-base text-secondaryVariant mb-8"
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
              <div className="flex justify-between items-center absolute bottom-3 md:bottom-5 left-0 right-0 px-4 lg:px-9">
                <button
                  className="text-statusColor text-lg font-medium hover:text-primary"
                  onClick={() => {
                    handleReadMore(trendingBlog);
                  }}
                >
                  Read more...
                </button>
                <div className="flex items-center gap-4">
                  <button
                    data-tooltip-id="0"
                    onMouseOver={() => setId("0")}
                    onMouseLeave={() => setId("")}
                    className="flex items-center gap-2 text-sm text-secondaryVariant2 hover:text-statusColor hover:scale-110 transform transition-transform duration-200"
                  >
                    <FiThumbsUp className="w-6 h-6" />
                    45
                  </button>
                  <button
                    data-tooltip-id="1"
                    onMouseOver={() => setId("1")}
                    onMouseLeave={() => setId("")}
                    className="flex items-center gap-2 text-sm text-secondaryVariant2 hover:text-statusColor hover:scale-110 transform transition-transform duration-200"
                  >
                    <TbMessageCircle className="w-6 h-6" />
                    23
                  </button>
                </div>{" "}
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 md:flex md:justify-end">
            <img
              className=" rounded-b-xl md:rounded-r-xl w-full"
              src={trendingBlog?.imageUrl}
              alt="trending blog"
            />
          </div>
        </div>
        <TooltipComponent id={id} content={tooltipText[id]} />
      </div>
    </>
  );
};

export default Trending;
