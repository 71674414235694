import React from "react";
import MainWrapper from "../../../shared/components/layout/MainWrapper";
import CenterViewBanner from "../../../shared/components/centerViewBanner/CenterViewBanner";
import BookSelectors from "../../components/bookSelectors/BookSelectors";

const MedEvaluationDate = () => {
  return (
    <>
      <MainWrapper>
        <div>
          <CenterViewBanner
            title={"Booking an Appointment"}
            imageClass={"medEvaluationDateBanner"}
          />
          <div className="container">
            <BookSelectors />
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default MedEvaluationDate;
