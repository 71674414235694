import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { CiBookmarkPlus } from "react-icons/ci";
import { FiThumbsUp } from "react-icons/fi";
import { TbMessageCircle } from "react-icons/tb";
import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";
import {
  formatDateBlog
} from "../../../shared/utils/formatDate";
import { IBlog } from "../../types/blog.interface";

interface Props {
  blog: IBlog;
  handleReadMore?: (blog: IBlog) => void;
}

const BlogCard: React.FC<Props> = ({ blog, handleReadMore }) => {
  const tooltipText: { [key: string]: string } = {
    "0": "Hit like",
    "1": "Add comment",
  };
  const [content, setContent] = useState("");
  const [id, setId] = useState("");
  const sanitizer = DOMPurify.sanitize;
  const sanitizedContent = sanitizer(blog?.content);
  // const contentWithoutImages = sanitizedContent.replace(/<img[^>]*>/g, '');


  useEffect(() => {
    const cleanedContent = sanitizedContent.replace(/\<img[^>]*\>/g, '');
    setContent(cleanedContent);
  }, [sanitizedContent]);


  return (
    <div className="col-span-12 md:col-span-6 lg:col-span-4 border border-secondaryVariant2 rounded-2xl p-2 md:p-6 hover:shadow-blogCardShadow relative h-[594px]">
      <img
        className="mb-5 h-56 w-full rounded-xl object-cover"
        src={blog?.imageUrl}
        alt="blog"
      />
      <div className="flex items-center justify-between mb-4 md:mb-6">
        <span className="bg-[#FAF8EB] py-2.5 px-5 rounded-full text-sm text-primary font-medium ">
          {blog?.category}
        </span>
        <button className="text-tertiary  hover:text-statusColor hover:scale-110 transform transition-transform duration-200">
          <CiBookmarkPlus className="w-6 h-6" />
        </button>
      </div>
      <h3 className="text-2xl font-semibold text-secondary mb-4 md:mb-6 line-clamp-2">
        {blog?.title}
      </h3>
      {/* <p className="line-clamp-3 max-w-[400px]  text-base text-secondaryVariant mb-8">
        {blog?.content}
      </p> */}
      <div
        className="line-clamp-3 max-w-[400px]  text-base text-secondaryVariant mb-8"
        dangerouslySetInnerHTML={{ __html: sanitizer(content) }}
      />
      <div className="absolute bottom-4 md:bottom-6 left-0 right-0 p-2 md:px-6">
        <button
          className="text-statusColor text-lg font-medium mb-4 md:mb-6 xl:mb-8 hover:text-primary"
          onClick={() => {
            handleReadMore && handleReadMore(blog);
          }}
        >
          Read more...
        </button>
        <div className="flex items-center justify-between ">
          <span className="text-sm font-medium text-secondaryVariant2">
            {formatDateBlog(blog?.pubDate)}
          </span>
          <div className="flex items-center gap-4">
            <button
              data-tooltip-id="0"
              onMouseOver={() => setId("0")}
              onMouseLeave={() => setId("")}
              className="flex items-center gap-2 text-sm text-secondaryVariant2 hover:text-statusColor hover:scale-110 transform transition-transform duration-200"
            >
              <FiThumbsUp className="w-6 h-6" />
              {blog?.likes}
            </button>
            <button
              data-tooltip-id="1"
              onMouseOver={() => setId("1")}
              onMouseLeave={() => setId("")}
              className="flex items-center gap-2 text-sm text-secondaryVariant2 hover:text-statusColor hover:scale-110 transform transition-transform duration-200"
            >
              <TbMessageCircle className="w-6 h-6" />
              {blog?.comments}
            </button>
          </div>
        </div>
      </div>
      <TooltipComponent id={id} content={tooltipText[id]} />
    </div>
  );
};

export default BlogCard;
