import moment from "moment";
import { medEvalApi } from "../../shared/utils/axios";
import { Service } from "../enums/service.enum";
import { Appointment, ICreateMedEvalPayload } from "../types/medEval.interface";
import { formatDateFull } from "../../shared/utils/formatDate";

export const createMedEvalBooking = async (
  state: string,
  service: string,
  appointmentDTime: Appointment
) => {
  try {
    const res: any = await medEvalApi.post(`/booking`, {
      service: service,
      state: state,
      appointment: appointmentDTime,
    });
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("expired token");
  } catch (err) {
    console.log("error: ", err);
  }
};
export const createMedEval = async (payload: ICreateMedEvalPayload) => {
  try {
    const res: any = await medEvalApi.post(``, payload);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("expired token");
  } catch (err) {
    console.log("error: ", err);
  }
};

export const validateSelections = async (
  state: string,
  service: string,
  appointmentDTime: Appointment
) => {
  try {
    const resp: any = await medEvalApi.post(`/validateMedicalEvaluation`, {
      amount: 1000,
      service: service,
      state: state,
      appointment: appointmentDTime,
    });
    console.log("res: ", resp);
    if (resp.ok && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

export const getMyAppointments = async () => {
  try {
    const resp: any = await medEvalApi.get(`/myAppointments`);

    if (resp.ok && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log("error: ", err);
  }
};

export const getAvailableSlots = async (date: Date | string) => {
  try {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const resp: any = await medEvalApi.get(`/availableSlots/${formattedDate}`);

    if (resp.ok && resp.data) {
      return resp.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log("error: ", err);
  }
};
