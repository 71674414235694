import React from "react";
import { CiCircleInfo } from "react-icons/ci";
import CostumDropdown from "../../../medEvaluation_module/components/customDropdown/CostumDropdown";
const SelectMethod = () => {
  const options = [
    {
      label: "9500 4500 **** 12*9",
      startIcon: (
        <img src="./images/MedEvaluation_images/selectesTick.svg" alt="tick" />
      ),
      endIcon: (
        <img
          src="./images/MedEvaluation_images/Visa-selected-icon.svg"
          alt="tick"
        />
      ),
    },
    {
      label: "9500 4500 **** 12*9",
      startIcon: (
        <img src="./images/MedEvaluation_images/selectesTick.svg" alt="tick" />
      ),
      endIcon: (
        <img
          src="./images/MedEvaluation_images/Visa-selected-icon.svg"
          alt="tick"
        />
      ),
    },
  ];
  return (
    <>
      <div className="max-w-[60%]">
        <h2 className="text-xl text-secondary font-semibold mb-9">
          Select your payment method
        </h2>
        <div className="grid grid-cols-2 gap-5 mb-9">
          <div className="hover:bg-secondaryNeutral py-3 rounded-lg border border-secondaryVariant2 relative">
            <div className="absolute left-2 top-1">
              <input type="radio" />
            </div>
            <div className="text-center flex flex-col items-center justify-center gap-2">
              <img
                src="./images/MedEvaluation_images/credit-card-icon.svg"
                alt="credit card"
              />
              <p className="text-base text-secondaryVariant flex flex-col items-center">
                <span>Pay with</span>
                Credit/Debit card
              </p>
            </div>
            <div></div>
          </div>
          <div className="hover:bg-secondaryNeutral py-3 rounded-lg border border-secondaryVariant2 relative">
            <div className="absolute left-2 top-1">
              <input type="radio" />
            </div>
            <div className="text-center flex flex-col items-center justify-center gap-2">
              <img
                src="./images/MedEvaluation_images/PayPal-img.svg"
                alt="credit card"
              />
              <p className="text-base text-secondaryVariant flex flex-col items-center">
                <span>Pay with</span>
                PayPal
              </p>
            </div>
            <div></div>
          </div>
        </div>
        <div>
          <h3 className="text-xl text-secondary font-semibold mb-2">
            Your Cards
          </h3>
          <div className="mb-6">
            <CostumDropdown options={options} placeholder="Select an option" />
          </div>{" "}
          <button className="flex items-center gap-3 mb-6">
            <img
              src="./images/MedEvaluation_images/credit-card-plus.svg"
              alt="credit-card-plus"
            />
            <span className="text-base text-statusColor underline">
              Add a new card
            </span>
          </button>
          <div className="mb-6">
            <label htmlFor="card" className="text-base text-secondaryVariant">
              Card Number
            </label>
            <input
              type="password"
              placeholder="Enter your Card Number"
              className="py-3.5 pe-2 ps-3 border border-secondaryVariant2 rounded-lg block mt-2 w-full"
            />
          </div>
          <div className="flex flex-row gap-3 mb-4 md:mb-7 xl:mb-10">
            <div className="basis-1/2">
              <label htmlFor="card" className="text-base text-secondaryVariant">
                Full name{" "}
              </label>
              <input
                type="name"
                placeholder="Qaisar Abbas"
                className="py-3.5 pe-2 ps-3 border border-secondaryVariant2 rounded-lg block mt-2 w-full"
              />
            </div>
            <div className="basis-1/4">
              <label
                htmlFor="Expires"
                className="text-base text-secondaryVariant"
              >
                Expires{" "}
              </label>
              <input
                type="text"
                placeholder="00/00"
                className="py-3.5 pe-2 ps-3 border border-secondaryVariant2 rounded-lg block mt-2 w-full"
              />
            </div>
            <div className="basis-1/4 relative">
              <label htmlFor="cvv" className="text-base text-secondaryVariant">
                CVV{" "}
              </label>
              <input
                type="password"
                placeholder="CVV"
                className="py-3.5 pe-2 ps-3 border border-secondaryVariant2 rounded-lg block mt-2 w-full"
              />
              <span className="absolute bottom-5 right-2">
                <CiCircleInfo className="w-4 h-4 text-secondaryVariant" />
              </span>
            </div>
          </div>
          <label
            htmlFor="check"
            className="flex items-center gap-4 cursor-pointer mb-4 md:mb-7 xl:mb-10"
          >
            <div className="relative">
              <input
                type="checkbox"
                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-secondaryVariant2 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-5 before:w-5 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 before:transition-opacity checked:border-statusColor checked:bg-statusColor"
                id="check"
              />
              <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-[40%] left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
            </div>
            <span className="text-base text-secondaryVariant">
              I want to choose this as default payment method as soon as it is
              validated.
            </span>
          </label>
          <button className="btnPrimary max-w-[260px]">Pay Now</button>
        </div>
      </div>
    </>
  );
};

export default SelectMethod;
