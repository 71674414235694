import React, { useEffect } from "react";
import MainWrapper from "../../components/layout/MainWrapper";

const PrivacyStatement = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <MainWrapper>
        <div className="bg-secondaryNeutral py-12">
          <div className="container">
            <h2 className="text-5xl text-secondary font-semibold mb-4">
              Child Care Forward Privacy Statement
            </h2>
            <span className="text-lg text-secondaryVariant font-medium">
              Last Updated: June 2024
            </span>
          </div>
        </div>
        <div className="container pt-24">
          <p className="text-base text-secondaryVariant mb-4 sm:mb-8 md:mb-16 xl:mb-24">
            Your privacy is important to us. This privacy statement explains the
            personal data Microsoft processes, how Microsoft processes it, and
            for what purposes. <br /> <br />
            Microsoft offers a wide range of products, including server products
            used to help operate enterprises worldwide, devices you use in your
            home, software that students use at school, and services developers
            use to create and host what’s next. References to Microsoft products
            in this statement include Microsoft services, websites, apps,
            software, servers, and devices.
            <br />
            Please read the product-specific details in this privacy statement,
            which provide additional relevant information. This statement
            applies to the interactions Microsoft has with you and the Microsoft
            products listed below, as well as other Microsoft products that
            display this statement.
            <br /> <br /> Young people may prefer starting with{" "}
            <span className="underline"> the Privacy for young people </span>
            page. That page highlights information that may be helpful for young
            people.
            <br /> <br /> For individuals in the United States, please refer to
            our
            <span className="underline"> U.S. State Data Privacy Notice </span>
            and the Washington State
            <span> Consumer Health Data Privacy Policy </span>for additional
            information about the processing of your personal data, and your
            rights under applicable U.S. State data privacy laws.
          </p>
          <div className="flex flex-row gap-16 items-start">
            <div className="basis-1/3 border rounded-lg p-2.5 bg-secondaryVariant2">
              <ul className="space-y-5 py-2.5">
                <li className="text-base text-secondary underline">
                  Personal data we collect
                </li>
                <li className="text-base text-secondary underline">
                  How we use personal data
                </li>
                <li className="text-base text-secondary underline">
                  Reasons we share personal data
                </li>
                <li className="text-base text-secondary underline">
                  How to access and control your personal data
                </li>
                <li className="text-base text-secondary underline">
                  Cookies and similar technologies
                </li>
                <li className="text-base text-secondary underline">
                  Microsoft account
                </li>
                <li className="text-base text-secondary underline">
                  Collection of data from children
                </li>
              </ul>
            </div>
            <div className="basis-2/3">
              <div className="mb-4 md:mb-10 xl:mb-16">
                <h2 className="text-2xl text-secondary">
                  Personal data we collect
                </h2>
                <hr className="my-6" />
                <p className="text-base text-secondaryVariant">
                  Lorem ipsum dolor sit amet, consectetur cdolor col adipiscing
                  elit. Integer mattis nunc augue vel lacinia erat euismod ut.
                  Sed eleifend tellus nonole tincidunt aliquet. Fusce aliquam mi
                  felis.Lorem ipsum dolor sit amet, consectetur cdolor col
                  adipiscing elit. Integer mattis nunc augue vel lacinia erat
                  euismod ut. Sed eleifend tellus nonole tincidunt aliquet.
                  Fusce aliquam mi felis.Lorem ipsum dolor sit amet, consectetur
                  cdolor col adipiscing elit. Integer mattis nunc augue vel
                  lacinia erat euismod ut. Sed eleifend tellus nonole tincidunt
                  aliquet. Fusce aliquam mi felis.Lorem ipsum dolor sit amet,
                  consectetur cdolor col adipiscing elit. Integer mattis nunc
                  augue vel lacinia erat euismod ut. Sed eleifend tellus nonole
                  tincidunt aliquet. Fusce aliquam mi felis.Lorem ipsum dolor
                  sit amet, consectetur cdolor col adipiscing elit. Integer
                  mattis nunc augue vel lacinia erat euismod ut. Sed eleifend
                  tellus nonole tincidunt aliquet. Fusce aliquam mi felis.Lorem
                  ipsum dolor sit amet, consectetur cdolor col adipiscing elit.
                  Integer mattis nunc augue vel lacinia erat euismod ut. Sed
                  eleifend tellus nonole tincidunt aliquet. Fusce aliquam mi
                  felis.
                </p>
              </div>
              <div className="mb-4 md:mb-10 xl:mb-16">
                <h2 className="text-2xl text-secondary">
                  How we use personal data
                </h2>
                <hr className="my-6" />
                <p className="text-base text-secondaryVariant">
                  Microsoft uses the data we collect to provide you with rich,
                  interactive experiences. In particular, we use data to:
                </p>
                <ul className="ps-7">
                  <li className="text-base text-secondaryVariant list-disc">
                    Provide our products, which includes updating, securing, and
                    troubleshooting, as well as providing support. It also
                    includes sharing data, when it is required to provide the
                    service or carry out the transactions you request.
                  </li>
                  <li className="text-base text-secondaryVariant list-disc">
                    Improve and develop our products.
                  </li>
                  <li className="text-base text-secondaryVariant list-disc">
                    Personalize our products and make recommendations.
                  </li>
                  <li className="text-base text-secondaryVariant list-disc">
                    Advertise and market to you, which includes sending
                    promotional communications, targeting advertising, and
                    presenting you with relevant offers.
                  </li>
                </ul>
                <p className="text-base text-secondaryVariant">
                  We also use the data to operate our business, which includes
                  analyzing our performance, meeting our legal obligations,
                  developing our workforce, and doing research. <br /> In
                  carrying out these purposes, we combine data we collect from
                  different contexts (for example, from your use of two
                  Microsoft products) or obtain from third parties to give you a
                  more seamless, consistent, and personalized experience, to
                  make informed business decisions, and for other legitimate
                  purposes. <br /> Our processing of personal data for these
                  purposes includes both automated and manual (human) methods of
                  processing. Our automated methods often are related to and
                  supported by our manual methods. For example, to build, train,
                  and improve the accuracy of our automated methods of
                  processing (including artificial intelligence or AI), we
                  manually review some of the output produced by the automated
                  methods against the underlying data.
                </p>
              </div>
              <div className="mb-4 md:mb-10 xl:mb-16">
                <h2 className="text-2xl text-secondary">
                  Reasons we share personal data
                </h2>
                <hr className="my-6" />
                <p className="text-base text-secondaryVariant">
                  We share your personal data with your consent or to complete
                  any transaction or provide any product you have requested or
                  authorized. We also share data with Microsoft-controlled
                  affiliates and subsidiaries; with vendors working on our
                  behalf; when required by law or to respond to legal process;
                  to protect our customers; to protect lives; to maintain the
                  security of our products; and to protect the rights and
                  property of Microsoft and its customers. <br /> Please note
                  that, as defined under certain U.S. state data privacy laws,
                  “sharing” also relates to providing personal data to third
                  parties for personalized advertising purposes. Please see the
                  <span className="underline"> U.S. State Data Privacy</span>
                  section below and our{" "}
                  <span className="underline">
                    {" "}
                    U.S. State Data Privacy Laws Notice
                  </span>
                  for more information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default PrivacyStatement;
