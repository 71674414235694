import React from "react";
import Topbar from "../Header/dashboard-header/Topbar";
import Sidebar from "../Header/dashboard-header/Sidebar";

const DashboardWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className="flex bg-natural">
        <Sidebar />
        <div className="flex-1">
          <Topbar />
          {children}
        </div>
      </div>
    </>
  );
};

export default DashboardWrapper;
