import React from "react";
import MainWrapper from "../../components/layout/MainWrapper";
import CenterViewBanner from "../../components/centerViewBanner/CenterViewBanner";

const AboutUs = () => {
  return (
    <>
      <MainWrapper>
        <CenterViewBanner title={"About Us"} imageClass={"aboutUsBanner"} />
        <div className="mt-24">
          <div className="flex flex-row items-center justify-between gap-4 mb-4 sm:mb-8 md:mb-16 xl:mb-24 container">
            <div className="basis-1/2">
              <img
                className="w-full"
                src="../images/vision-statement-img.png"
                alt="vision"
              />
            </div>
            <div className="basis-1/2 max-w-[480px]">
              <h2 className="text-2xl md:text-xll xl:text-4xl text-secondary font-semibold mb-6">
                Vision Statement
              </h2>
              <p className="text-base text-secondaryVariant">
                Providing innovations to increase operational efficiency for
                Child Care Centers, decrease cost of Child Care for parents, and
                eliminate unnecessary hurdles for teachers.
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between gap-4 mb-4 sm:mb-8 md:mb-16 xl:mb-24 container">
            <div className="basis-1/2 max-w-[480px]">
              <h2 className="text-2xl md:text-xll xl:text-4xl text-secondary font-semibold mb-6">
                Mission Statement
              </h2>
              <p className="text-base text-secondaryVariant">
                We will accomplish our vision by bringing together centers,
                teachers, and parents and providing them with tools and
                services​ that increase operational efficiency of Childcare
                centers, remove bureaucratic hurdles for teachers, and
                ultimately decrease the cost of Childcare for parents.​
              </p>
            </div>
            <div className="basis-1/2">
              <img
                className="w-full"
                src="../images/mission-statement-img.png"
                alt="mission"
              />
            </div>
          </div>
          <div className="container">
            <h2 className="text-2xl md:text-xll xl:text-4xl text-secondary font-semibold mb-8">
              How are we moving Child Care Forward!{" "}
            </h2>
            <div className="flex flex-row gap-16 mb-4 md:mb-8 xl:mb-12">
              <div className="basis-1/2 border border-secondaryVariant2 rounded-xl p-6">
                <h2 className="text-[28px] font-semibold text-secondary mb-6">
                  Existing Inefficiencies in Child Care
                </h2>
                <p className="text-base text-secondaryVariant">
                  Child Care suffers from many inefficiencies – some that are
                  easy to eliminate and some that are so entrenched that it may
                  take a decade or so to eliminate. An example of the latter is
                  staff turnover. Even before the pandemic, that is before March
                  2020, there was high turnover in the industry – not a
                  surprising fact. Taking care of children all day long is not
                  easy, and teachers can burn out. Additionally, there is no
                  staffing support from the local government – like there is for
                  the public school system (such as a substitute pool to draw
                  from when staffing shortages occur).
                </p>
              </div>
              <div className="basis-1/2 border border-secondaryVariant2 rounded-xl p-6">
                <h2 className="text-[28px] font-semibold text-secondary mb-6">
                  Challenges Exacerbated by the Pandemic
                </h2>
                <p className="text-base text-secondaryVariant">
                  There were, of course, many other problems before March 2020.
                  Add to these the COVID pandemic, the great resignation, and
                  other economic strains since the pandemic, and there are
                  certainly more hardships to face. Many Child Care centers have
                  shut down, the cost of Child Care has increased, and staffing
                  shortages have increased exponentially. The issues are myriad,
                  some easy to solve and some not.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-secondaryNeutral border border-secondaryVariant2 rounded-xl p-9 mb-4 sm:mb-8 md:mb-16 xl:mb-24">
            <div className="container flex flex-row gap-24 items-center">
              <div className="basis-1/2">
                <img
                  src="../images/towords-solution-image.png"
                  alt="towards Solution"
                />
              </div>
              <div className="basis-1/2">
                <h2 className="text-[28px] text-secondary font-semibold mb-8">
                  Steps Towards Solutions
                </h2>
                <p className="text-base text-secondaryVariant">
                  Our goal is to start with problems that have easy solutions,
                  such as making the paperwork to get hired easier and faster
                  for teachers. Meanwhile, we will also be working on some of
                  the harder problems, such as convincing the local government
                  to provide Child Care the same support they provide for public
                  school system teachers. Our goal is to work methodically and
                  scientifically, to gather solid evidence for changing the
                  system in a way that makes Child Care efficient for Centers,
                  affordable for parents, and easier for teachers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </MainWrapper>
    </>
  );
};

export default AboutUs;
