import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface Props {
  id: string;
  place?: "top" | "bottom" | "left" | "right";
  className?: string;
  content: string;
}
export const TooltipComponent = ({
  id,
  place = "top",
  className = "bg-primary text-white",
  content,
}: Props) => {
  return (
    <ReactTooltip
      id={id}
      place={place}
      content={content}
      className={className}
    />
  );
};
