import React from "react";

interface Props {
  imageClass: string;
  title: string;
}
const CenterViewBanner: React.FC<Props> = ({ imageClass, title }) => {
  return (
    <>
      <div
        className={`${imageClass} py-8 md:py-12 xl:py-20 h-28 md:h-44 xl:h-52  mb-4 xl:mb-6`}
      >
        <h2 className="text-white text-2xl md:text-xll xl:text-4xl font-semibold mx-auto flex justify-center">
          {title}
        </h2>
      </div>
    </>
  );
};

export default CenterViewBanner;
